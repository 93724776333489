import 'date-fns';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ImageUpload from '../generic/ImageUpload';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

  },
  stepper: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  form: {
    textAlign: "left",
    padding: '10px',
  },
  formBottom: {
    textAlign: 'right',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  titleFields: {
    fontSize: '14px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  valueFields: {
    fontSize: '12px',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1',
    letterSpacing: '0.00938em',
    fontSize: '12px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  imageFluid: {
    width: '100%',
    height: 'auto',
  },
}));


function getSteps() {
  return ['Cover and Report Info', 'Overview', 'Milestones', 'Safety', 'Environment', 'Planning and Control', 'Quality', 'Commissioning Production', 'Pendencies Control', 'Photografic Report'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "";
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown stepIndex';
  }
}



export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [selectedDate, setSelectedDate] = React.useState(null);
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 ? (
        <form>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth className={classes.form}>
                <TextField label="Process" defaultValue="Lorem ipsum dolor sit amet"
                  InputProps={{
                    readOnly: true,
                  }} />
              </FormControl>
              <FormControl fullWidth className={classes.form}>
                <TextField label="Area" defaultValue="Proin ligula tellus, fermentum vel tempor se"
                  InputProps={{
                    readOnly: true,
                  }} />
              </FormControl>
              <FormControl fullWidth className={classes.form}>
                <TextField label="Contratacted" defaultValue="Quisque risus nunc"
                  InputProps={{
                    readOnly: true,
                  }} />
              </FormControl>
              <FormControl fullWidth className={classes.form}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    required
                    margin="normal"
                    id="date-picker-dialog"
                    label="Report date"
                    format="MM/yyyy"
                    views={["year", "month"]}
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid className={classes.form} item xs={12} sm={4}>
              <FormControl fullWidth className={classes.form}>
                <Typography className={classes.instructions}>Cover image</Typography>
                <ImageUpload id="logo-image-upload" />
              </FormControl>
            </Grid>
            <Grid className={classes.form} item xs={12} sm={4}>
              <FormControl fullWidth className={classes.form}>
                <TextField label="Contractor" defaultValue="Fulano"
                  InputProps={{
                    readOnly: true,
                  }} />
              </FormControl>
              <FormControl fullWidth className={classes.form}>
                <TextField label="Planner Specialist" defaultValue="Beltrano"
                  InputProps={{
                    readOnly: true,
                  }} />
              </FormControl>
              <FormControl fullWidth className={classes.form}>
                <TextField label="Support Specialist" defaultValue="ZZZZZZZ"
                  InputProps={{
                    readOnly: true,
                  }} />
              </FormControl>
              <FormControl fullWidth className={classes.form}>
                <TextField label="Area Coordinator" defaultValue="XXXXXXXXXXXX"
                  InputProps={{
                    readOnly: true,
                  }} />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      ) : activeStep === 1 ? (
        <form>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth className={classes.form}>
                <TextField required multiline label="Overview" defaultValue="" />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl fullWidth className={classes.form}>
                <Typography className={classes.instructions}>Timeline</Typography>
                <img src={'./timeline.png'} className={classes.imageFluid} />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      ) : (
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
              <Grid className={classes.form} item xs={12}>
                <Typography className={classes.instructions}>Under construction</Typography>
              </Grid>
            </Grid>
          )
      }
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid className={classes.formBottom} item xs={12}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.backButton}>Back</Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Grid>
      </Grid>
    </div >
  );
}