import React from 'react';
import './App.css';
import 'typeface-roboto';
import Container from '@material-ui/core/Container';
import HorizontalLabelPositionBelowStepper from './components/stepper/HorizontalLabelPositionBelowStepper'
function App() {
  return (
    <Container maxWidth="xl" className="App">
      <header className="App-header" >
        <HorizontalLabelPositionBelowStepper />
      </header>
    </Container>
  );
}

export default App;
